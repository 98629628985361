<template>
  <section class="hero is-primary is-medium">
    <div class="hero-head">
      <nav class="navbar">
        <div class="container">
          <div class="navbar-brand">
            <a class="navbar-item">
              <img src="./assets/logo.png" alt="Logo" />
              PlayDreamGames
            </a>
            <span class="navbar-burger" data-target="navbarMenuHeroA">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <div id="navbarMenuHeroA" class="navbar-menu">
            <div class="navbar-end">
              <a class="navbar-item"  @click="scrollToSection('game')"> Games </a>
              <a class="navbar-item"  @click="scrollToSection('about')"> About Us </a>
              <a class="navbar-item"  @click="scrollToSection('contacts')"> Contacts </a>
            </div>
          </div>
        </div>
      </nav>
      <div class="hero slide">
        <div id="navbarMenuHeroA" class="navbar-menu">
            <div class="navbar-end">
              <a class="navbar-item active"> </a>
              <a class="navbar-item"> </a>
              <a class="navbar-item"> </a>
            </div>
          </div>
      </div>
    </div>
    <!-- Hero content: will be in the middle -->
    <div class="hero-body welcome">
    </div>
    <div ref="game" class="hero-body game">
    </div>
    <div ref="about" class="hero-body about">
      <div class="container">
        <p class="title aboutText">About Us</p>
        <p class="subtitle aboutTextDetail">
          We are a game company founded in 2024, focusing on the development and distribution of overseas casual games. Our company members are relatively young and energetic. Our aim is to only make fun games!
        </p>
      </div>
    </div>
    
    <div ref="contacts" class="hero-body contacts">
      <div class="container">
        <p class="title contactsText">Contacts</p>
        <p class="subtitle contactsTextDetail">
          Questions? Suggestions? we'd love to hear from you!
        </p>
        <br>
        <!-- Main container -->
        <nav class="level">
          <!-- Left side -->
          <div class="level-left">
            <img src="./assets/images/img_pic1.png" />
          </div>

          <!-- Right side -->
          <div class="level-right">
            <img src="./assets/images/img_pic2.png" />
          </div>
        </nav>
        <nav class="level">
          <!-- Left side -->
          <div class="level-item toLeft">
            <img src="./assets/images/icon_location.png" />
            <p class="subtitle supportText">Hangzhou, Zhejiang, China </p>
          </div>

          <!-- Right side -->
          <div class="level-item has-text-centered">
            <img src="./assets/images/icon_email.png" />
            <p class="subtitle supportText">support@playdreamgames.com</p>
          </div>
        </nav>

        <br><br>
        <!-- <nav class="level" :hidden="true">
          <div class="level-item has-text-centered">
            <div>
              <a class="policyText">TERMS OF USE</a>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <a class="policyText">PRIVACY POLICY</a>
            </div>
          </div>
        </nav> -->
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'NavbarWithCustomHeight',
  methods: {
    scrollToSection(section) {
      // 获取目标元素并平滑滚动到该元素
      this.$refs[section].scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }
}
</script>

<style>
@font-face {
  font-family: 'tahomabd';
  src: url('@/assets/fonts/tahomabd.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

a.navbar-item:hover {
  background-color: transparent !important; /* 去除背景色变化 */
  color: inherit !important; /* 保持原始文字颜色 */
  text-decoration: none !important; /* 去掉下划线 */
}

body {
  font-family: 'tahomabd';
  font-size: 2rem !important;
  color: white !important;
}

.hero-body {
  padding-top: 92px; /* 为了避免导航栏遮住内容，可以调整主内容区域的顶部填充 */
}
.navbar {
  height: 82px;
  font-family: 'tahomabd';
  background-color: #111726 !important;
  font-weight: bold;

  /* border-bottom: 10px solid #77ACBD; */
}
.navbar-item img {
  max-height: 4rem !important;
  padding-right: 0.3rem;
}
.navbar-brand .navbar-tabs {
  padding-left: 1rem !important;
}

.slide {
  height: 10px;
  background-color: #77ACBD;
}

.hero-head {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* 使其充满页面宽度 */
  z-index: 1000; /* 确保它显示在其他内容之上 */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 可选：为固定的导航栏添加阴影效果 */
}
.welcome {
  background-image: url('@/assets/images/page1.png');
  background-size:cover; /* 背景图自适应，保持比例填满容器 */
  background-position: center; /* 背景图居中对齐 */
  background-repeat: no-repeat; /* 不重复背景图 */
  height: 100vh; 
}
.game {
  background-image: url('@/assets/images/page4.png');
  background-size: cover; /* 背景图自适应，保持比例填满容器 */
  background-position: center; /* 背景图居中对齐 */
  background-repeat: no-repeat; /* 不重复背景图 */
  height: 100vh; 
}
.about {
  background-image: url('@/assets/images/page3.png');
  /* height: 1000px;  */
  height: 100vh; 
  background-size: cover; /* 背景图自适应，保持比例填满容器 */
  background-position: center; /* 背景图居中对齐 */
  background-repeat: no-repeat; /* 不重复背景图 */
}
.aboutText {
  color: black !important;
  font-family: 'tahomabd';
  font-size: 2.5rem !important;
  font-weight: bold;
  word-wrap: break-word;
}

.aboutTextDetail {
  padding-top: 2rem;
  text-indent: 2em;
  line-height: 1.6 !important;
  color: black !important;
  font-family: 'tahoma' !important;
  font-size: 1rem !important;
  word-wrap: break-word;
  width: 60%;
}

.contacts {
  background-color: #111726 !important;
  height: 1000px; 
}

.contactsText {
  font-family: 'tahomabd';
  font-size: 2.5rem !important;
  font-weight: bold;
  word-wrap: break-word;
}

.contactsTextDetail {
  font-family: 'tahoma';
  font-size: 1.2rem !important;
  word-wrap: break-word;
}

.supportText {
  font-family: 'tahoma';
  font-size: 1.8rem !important;
  padding-left: 0.3em;
}

.policyText {
  font-family: 'tahomabd';
  font-size: 1.5rem !important;
  font-weight: bold;
  word-wrap: break-word;
}
.toLeft {
  justify-content:left !important;
}
</style>
